<template>
<div class="home-page-wrapper">
  <Header />

<!--  mobile menu-->
  <MobileMenu />
<!--  this is the a combination of the hero and services section-->
   <section class="position-relative">
    <Hero :hero="hero">
      <ExxtraCash />
    </Hero>
    <Features />
    <Requirements :requirements="requirements" />
    <div class="position-absolute design" style="top: 27%;">
      <div class="d-none pc">
        <svg width="290" height="414" viewBox="0 0 290 414" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.2">
            <path
                d="M-59.2238 1.52588e-05H-148.731L205.384 357.05L290 353.137L150.604 213.251L290 69.4535H205.384L104.628 167.275L82.1287 146.244L222.992 1.52588e-05H135.931L38.1089 97.3327L-59.2238 1.52588e-05Z"
                fill="#ADCD39"
/>
            <path
                d="M-118.406 69.4535H-204L-65.5822 209.339L-204 346.289H-118.406L-21.5624 254.337L0.93663 273.901L-136.992 413.786H-51.398L90.9327 273.901L-118.406 69.4535Z"
                fill="#459042"
/>
          </g>
        </svg>
      </div>
      <div class="d-none mobile">
        <svg width="144" height="217" viewBox="0 0 144 217" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.2">
            <path
                d="M-39.1505 7.62939e-06H-86.0439L99.4799 187.061L143.811 185.011L70.78 111.724L143.811 36.3873H99.4799L46.6927 87.637L34.9053 76.6183L108.705 7.62939e-06H63.0926L11.8429 50.9934L-39.1505 7.62939e-06Z"
                fill="#ADCD39"
/>
            <path
                d="M-70.1565 36.3873H-115L-42.4817 109.674L-115 181.424H-70.1565L-19.4193 133.249L-7.63193 143.499L-79.894 216.786H-35.0505L39.5178 143.499L-70.1565 36.3873Z"
                fill="#459042"
/>
          </g>
        </svg>
      </div>

    </div>
  </section>

  <Footer />
</div>
</template>

<script>
import { BLink, BRow, } from 'bootstrap-vue'


import Footer from "@/pages/public/services/shared/Footer.vue";
import MobileMenu from "@/pages/public/MobileMenu.vue";
import Requirements from "@/pages/public/services/shared/Requirements.vue";
import Hero from "@/pages/public/services/shared/Hero.vue";

import Header from "@/pages/public/header.vue";
import Features from "./Features.vue"


export default {
  components: {
    MobileMenu,
    Header,
    Footer,
    Hero,
    Features,
    Requirements,
    BRow,
    BLink
  },
  data() {
    return {
      hero: {
        title: "Exxtra Cash",
        description: "Access quick funds without breaking into your contributions with Exxtra Cash. Our short- to medium-term Exxtra Cash loans are ideal if you need a little extra financial support to meet your personal financing needs.",
        icon: "exxtra-cash"
      },
      requirements: [
        "Applicants must be 21 years but not more than 55 years.",
        "Applicants must provide a valid salary account",
        "Applicants will complete an online application form",
      ]
    }
  },
  methods: {
    showMobileMenu() {
      const menu = document.getElementById('mobile-menu');
      menu.classList.toggle('hidden');
      document.body.classList.toggle('no-scroll');
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-public.scss';
</style>
